import styled from '@emotion/styled'

import { PerformanceMonitoringIcon, HostIcon } from '@segment/matcha/components/Icons/Brandbook'
import { ChevronRightIcon } from '@segment/matcha/components/Icons/Common'
import ColumnTrack from '@segment/matcha/components/ColumnTrack'

import CardBase, * as Card from '@segment/matcha/recipes/Card'

import CopyTextBase, * as CopyText from '@segment/matcha/recipes/CopyText'
import SectionBase, * as Section from '@segment/matcha/recipes/Section'

import getAbsoluteUrl from '@segment/utils/getAbsoluteUrl'

import { usePageConfig } from '@segment/contexts/page'
const cards = [
  {
    icon: <PerformanceMonitoringIcon />,
    title: `Segment  Engineering`,
    body: `Segment is the single platform to collect and manage analytics data.`,
    link: `/engineering/`,
  },
  {
    icon: <HostIcon />,
    title: `Engineering Blog`,
    body: `A showcase of solutions and best practices from Segment engineers.`,
    link: `https://segment.com/blog/category/engineers-and-developers/`,
  },
]

const copy = {
  heading: `More resources`,
  description: `We use these open source projects as the foundations of our infrastructure to handle billions of API calls per day.`,
}

export const MoreResources = () => {
  const pageConfig = usePageConfig()
  return (
    <Wrapper>
      <StyledSectionBase variant="centered">
        <StyledSectionCopy>
          <StyledCopyTextBase centered={true}>
            <CopyText.Heading tag="h3">{copy.heading}</CopyText.Heading>
            <CopyTextDescription tag="p" variant="pLarge">
              {copy.description}
            </CopyTextDescription>
          </StyledCopyTextBase>
        </StyledSectionCopy>
        <Section.Contents>
          <StyledColumnTrack columns={1} overrides={{ md: 2 }}>
            {cards.map((card, index) => (
              <CardBase key={index} layout="vertical" href={getAbsoluteUrl(card.link, pageConfig)}>
                <Card.Body>
                  <Card.Accessories>
                    <Card.AccessoriesRow>{card.icon}</Card.AccessoriesRow>
                  </Card.Accessories>
                  <Card.Copy>
                    <Card.Headline variant="h4" tag="h4">
                      {card.title}
                    </Card.Headline>
                    <Card.Description variant="pLarge">{card.body}</Card.Description>
                  </Card.Copy>
                  <Card.Action alignment="right">
                    <ChevronRightIcon />
                  </Card.Action>
                </Card.Body>
              </CardBase>
            ))}
          </StyledColumnTrack>
        </Section.Contents>
      </StyledSectionBase>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.palette.twilio.gray[10]};
`

const StyledCopyTextBase = styled(CopyTextBase)`
  ${({ theme }) => theme.breakpoints.down(`sm`)} {
    padding-bottom: 40px;
  }
`

const StyledColumnTrack = styled(ColumnTrack)`
  ${({ theme }) => theme.breakpoints.down(`md`)} {
    max-width: 736px;
  }
`

const CopyTextDescription = styled(CopyText.Description)`
  max-width: 532px;
  height: 64px;
  text-align: center;
`

const StyledSectionBase = styled(SectionBase)`
  max-width: 1280px;
  padding-bottom: 120px;
  ${({ theme }) => theme.breakpoints.down(`md`)} {
    padding-bottom: 0px;
  }
  ${Section.Wrapper} {
    ${({ theme }) => theme.breakpoints.down(`md`)} {
      row-gap: 0;
    }
    padding-bottom: 0;
  }
`

const StyledSectionCopy = styled(Section.Copy)`
  max-width: 844px;
  ${({ theme }) => theme.breakpoints.down(`md`)} {
    padding-bottom: 40px;
  }
`

export default MoreResources
