import styled from '@emotion/styled'

import { AppearanceProvider } from '@segment/matcha/foundations/AppearanceContext'

import Footer from '@segment/templates/Footer'
import Navigation from '@segment/templates/Navigation'
import GettingStarted from '@segment/templates/GettingStarted'

import Layout from '@segment/next-shared/layout'

import Hero from './components/Hero/Hero'

import OurOpenSource from './components/OurOpenSource'
import MoreResources from './components/MoreResources'

import Cards from './components/Cards'

import ogImagePng from './assets/OgOpenSource.png'

const OpensourceView = () => {
  return (
    <AppearanceProvider appearance="twilioLight">
      <Layout
        pageTitle="Twilio Segment Open"
        pageDescription="Twilio Segment started with an open source project."
        pageOG={ogImagePng}
      >
        <Navigation />
        <Hero />
        <Main>
          <Cards />
          <OurOpenSource />
          <MoreResources />
          <GettingStarted />
        </Main>
        <Footer />
      </Layout>
    </AppearanceProvider>
  )
}

const Main = styled.main`
  background-color: ${({ theme }) => theme.palette.neutrals.white[100]};
`

export default OpensourceView
