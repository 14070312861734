import type { FC, PropsWithChildren } from 'react'
import styled from '@emotion/styled'

import Typography from '@segment/matcha/components/Typography'

import CopyTextBase, * as CopyText from '@segment/matcha/recipes/CopyText'
import HeroBase, * as HeroRecipe from '@segment/matcha/recipes/Hero'

const copy = {
  heading: `Segment started with an open source project.`,
  description: `Since then, we’ve open sourced hundreds of our repos. \n We want to continue supporting the community by publishing \n our code and other developers’ awesome open source projects.`,
}

const Hero: FC<PropsWithChildren<unknown>> = () => {
  return (
    <Wrapper>
      <HeroComponent variant="centered">
        <HeroCopy>
          <CopyTextBase centered={true}>
            <StyledCopyTextHeading variant="h1" tag="h1">
              {copy.heading}
            </StyledCopyTextHeading>
            <StyledDescription variant="pLarge" tag="p">
              {copy.description}
            </StyledDescription>
          </CopyTextBase>
        </HeroCopy>
      </HeroComponent>
    </Wrapper>
  )
}

const Wrapper = styled.header`
  background-color: ${({ theme }) => theme.palette.twilio.green[10]};
`

const HeroComponent = styled(HeroBase)`
  ${HeroRecipe.Wrapper} {
    padding-bottom: 0;
    ${({ theme }) => theme.breakpoints.up(`lg`)} {
      padding-left: 0;
      padding-right: 0;
    }
    ${({ theme }) => theme.breakpoints.down(`md`)} {
      row-gap: 20px;
    }
    ${({ theme }) => theme.breakpoints.down(`sm`)} {
      padding-bottom: 20px;
    }
  }
`

const StyledDescription = styled(Typography)`
  white-space: pre-line;
  ${({ theme }) => theme.breakpoints.down(`sm`)} {
    max-width: 844px;
    white-space: normal;
  }
  ${({ theme }) => theme.breakpoints.only(`xs`)} {
    white-space: pre-line;
  }
`

const StyledCopyTextHeading = styled(CopyText.Heading)`
  ${({ theme }) => theme.breakpoints.down(`md`)} {
    max-width: 690px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }

  ${({ theme }) => theme.breakpoints.down(`xs`)} {
    padding-top: 0;
  }
  ${({ theme }) => theme.breakpoints.only(`md`)} {
    margin-bottom: 0;
  }
`

const HeroCopy = styled(HeroRecipe.Copy)`
  max-width: 844px;
  ${({ theme }) => theme.breakpoints.up(`lg`)} {
    margin-bottom: -75px;
    z-index: 1;
  }
`

export default Hero
