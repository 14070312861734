const data = [
  {
    key: `javascript`,
    title: `JavaScript/TypeScript`,
    cards: [
      {
        badge: `JavaScript`,
        title: `Evergreen`,
        description: `React UI framework that implements our Evergreen design system.`,
        link: `https://github.com/segmentio/evergreen`,
      },
      {
        badge: `TypeScript`,
        title: `analytics-next`,
        description: `The hassle-free way to integrate analytics into any web application.`,
        link: `https://github.com/segmentio/analytics-next`,
      },
      {
        badge: `TypeScript`,
        title: `Consent Manager`,
        description: `A drop-in consent management plugin for analytics.js.`,
        link: `https://github.com/segmentio/consent-manager`,
      },
      {
        badge: `JavaScript`,
        title: `in-eu`,
        description: `A privacy-conscious EU detection browser library.`,
        link: `https://github.com/segmentio/in-eu`,
      },
      {
        badge: `JavaScript`,
        title: `analytics.js-integrations`,
        description: `The monorepo storing most of the analytics.js integrations supported by Segment.`,
        link: `https://github.com/segmentio/analytics.js-integrations`,
      },
      {
        badge: `TypeScript`,
        title: `ts-mysql-plugin`,
        description: `A TypeScript language service plugin that gives superpowers to SQL tagged template literals.`,
        link: `https://github.com/segmentio/ts-mysql-plugin`,
      },
    ],
  },
  {
    key: `go`,
    title: `Go`,
    cards: [
      {
        badge: `Go`,
        title: `ksuid`,
        description: `K-sortable globally unique IDs.`,
        link: `https://github.com/segmentio/ksuid`,
      },
      {
        badge: `Go`,
        title: `encoding`,
        description: `Encoders and decoders for various data formats, including JSON and ISO 8601.`,
        link: `https://github.com/segmentio/encoding`,
      },
      {
        badge: `Go`,
        title: `asm`,
        description: `Algorithms implemented in assembly to unlock the full power of modern CPUs.`,
        link: `https://github.com/segmentio/asm`,
      },
      {
        badge: `Go`,
        title: `golines`,
        description: `A Go formatter that shortens long lines.`,
        link: `https://github.com/segmentio/golines`,
      },
      {
        badge: `Go`,
        title: `stats`,
        description: `A Go package for abstracting stats collection.`,
        link: `https://github.com/segmentio/stats`,
      },
      {
        badge: `Go`,
        title: `conf`,
        description: `A Go package for loading program configuration from multiple sources.`,
        link: `https://github.com/segmentio/conf`,
      },
    ],
  },
  {
    key: `messaging`,
    title: `Messaging`,
    cards: [
      {
        badge: `Go`,
        title: `kafka-go`,
        description: `Easy-to-use APIs for interacting with Kafka.`,
        link: `https://github.com/segmentio/kafka-go`,
      },
      {
        badge: `Go`,
        title: `nsq-go`,
        description: `Tools for building NSQ clients, servers and middleware. `,
        link: `https://github.com/segmentio/nsq-go`,
      },
      {
        badge: `Go`,
        title: `topicctl`,
        description: `A tool for easy, declarative management of Kafka topics.`,
        link: `https://github.com/segmentio/topicctl`,
      },
    ],
  },
  {
    key: `microservices`,
    title: `Microservices`,
    cards: [
      {
        badge: `Go`,
        title: `Chamber`,
        description: `A tool for managing secrets.`,
        link: `https://github.com/segmentio/chamber`,
      },
      {
        badge: `Go`,
        title: `ctlstore`,
        description: `A distributed data store with very low latency, always-available, and "infinitely" scalable reads.`,
        link: `https://github.com/segmentio/ctlstore`,
      },
      {
        badge: `Go`,
        title: `kubectl-curl`,
        description: `A kubectl plugin to run curl commands against Kubernetes pods.`,
        link: `https://github.com/segmentio/kubectl-curl`,
      },
    ],
  },
]

export default data
