import styled from '@emotion/styled'

import ColumnTrack from '@segment/matcha/components/ColumnTrack'
import Badge from '@segment/matcha/components/Badge'
import { ChevronRightIcon } from '@segment/matcha/components/Icons/Common'

import CardBase, * as Card from '@segment/matcha/recipes/Card'
import SectionBase, * as Section from '@segment/matcha/recipes/Section'
import CopyTextBase, * as CopyText from '@segment/matcha/recipes/CopyText'
import TabsBase, * as Tabs from '@segment/matcha/recipes/Tabs'
import useTabs from '@segment/matcha/recipes/Tabs/useTabs'

import data from './data'

const copy = {
  heading: `Open source \n projects we use`,
  description: `We use these open source projects as the foundations of our infrastructure to handle billions of API calls per day and to allow us to rapidly build and test code on the client.`,
}

const button = {
  url: `https://github.com/segmentio`,
  text: `View all projects`,
}

export const OurOpenSource = () => {
  const { getTrackProps, getControlProps, getPanelProps, activeTabKey } = useTabs(0, data.length, `open-source`)

  return (
    <StyledSectionBase variant="centered">
      <SectionCopyHead>
        <CopyTextBase centered={true}>
          <StyledCopyTextHeading tag="h3" variant="h2">
            {copy.heading}
          </StyledCopyTextHeading>
          <CopyTextDescription tag="p">{copy.description}</CopyTextDescription>
        </CopyTextBase>
      </SectionCopyHead>
      <Section.Contents>
        <SectionComponent variant="centered">
          <Section.Copy>
            <CopyTextBase centered={true}>
              <TabsBase variant="filled">
                <Tabs.Track {...getTrackProps()}>
                  {data.map((tab, index) => (
                    <Tabs.Control {...getControlProps(index)} key={tab.key}>
                      {tab.title}
                    </Tabs.Control>
                  ))}
                </Tabs.Track>
              </TabsBase>
            </CopyTextBase>
          </Section.Copy>
        </SectionComponent>
        <Section.Contents>
          <TabsLayout>
            <Slide {...getPanelProps(activeTabKey)}>
              <StyledColumnTrack columns={1} overrides={{ md: 2, lg: 3 }} center={true}>
                {data[activeTabKey].cards.map((card) => {
                  return (
                    <StyledCardBase href={card.link} key={card.title} layout="vertical">
                      <Card.Body>
                        <Card.Accessories>
                          <Card.AccessoriesRow>
                            <Badge variant="outlined">{card.badge}</Badge>
                          </Card.AccessoriesRow>
                        </Card.Accessories>
                        <Card.Copy>
                          <Card.Headline>{card.title}</Card.Headline>
                          <Card.Description>{card.description}</Card.Description>
                        </Card.Copy>
                        <Card.Action alignment="right">
                          <ChevronRightIcon />
                        </Card.Action>
                      </Card.Body>
                    </StyledCardBase>
                  )
                })}
              </StyledColumnTrack>
            </Slide>
          </TabsLayout>
        </Section.Contents>
      </Section.Contents>
      <CopyTextBase centered={true}>
        <CopyText.Link href={button.url}> {button.text} </CopyText.Link>
      </CopyTextBase>
    </StyledSectionBase>
  )
}

const StyledCopyTextHeading = styled(CopyText.Heading)`
  ${({ theme }) => theme.breakpoints.down(`xs`)} {
    text-align: center;
    white-space: pre-line;
  }
`

const CopyTextDescription = styled(CopyText.Description)`
  max-width: 844px;
`

const SectionCopyHead = styled(Section.Copy)`
  padding-bottom: 40px;
`

const StyledCardBody = styled(Card.Body)`
  margin-top: -10px;
  ${({ theme }) => theme.typography.pSmall.css};
`

const StyledSectionBase = styled(SectionBase)`
  ${Section.Wrapper} {
    max-height: 100%;
    row-gap: 0;
  }
`

const SectionComponent = styled(SectionBase)`
  max-width: 1280px;
  padding-bottom: 24px;
  ${({ theme }) => theme.breakpoints.down(`md`)} {
    padding-bottom: 20px;
    margin-bottom: -60px; // needed because variant filled includes a padding in the TabWrapper Object
  }
  overflow-x: visible;
  ${Section.Wrapper} {
    padding: 0;
  }
`

const Slide = styled(Tabs.Panel)`
  max-width: 100%;
  flex-shrink: 1;

  ${({ theme }) => theme.breakpoints.only(`md`)} {
    max-width: 736px;
    align-self: center;
  }
  ${({ theme }) => theme.breakpoints.down(`md`)} {
    row-gap: 48px;
  }
  ${({ theme }) => theme.breakpoints.up(`lg`)} {
    column-gap: 120px;
    row-gap: 0;
  }
`

const StyledCardBase = styled(CardBase)`
  max-width: 352px;
  height: 100%;
  min-width: 0;
  overflow-wrap: break-word;

  ${({ theme }) => theme.breakpoints.only(`sm`)} {
    max-width: 100%;
  }
  ${({ theme }) => theme.breakpoints.only(`xs`)} {
    max-width: 100%;
  }
  :hover {
    ${StyledCardBody} {
      color: ${({ theme }) => theme.palette.neutrals.white[100]};
    }
  }
`

const StyledColumnTrack = styled(ColumnTrack)`
  max-width: 1120px;
  align-items: stretch;
  justify-items: stretch;
  padding-left: 0;
  padding-right: 0;
  margin: 0 auto;
  ${({ theme }) => theme.breakpoints.down(`md`)} {
    max-width: 100%;
  }
`

const TabsLayout = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
  width: 100%;
  padding-top: 20px;
  padding-bottom: 56px;
  ${({ theme }) => theme.breakpoints.only(`md`)} {
    align-items: center;
  }
  ${({ theme }) => theme.breakpoints.down(`md`)} {
    flex-wrap: nowrap;
    align-items: stretch;
  }
`

export default OurOpenSource
