import styled from '@emotion/styled'

import ColumnTrack from '@segment/matcha/components/ColumnTrack'
import Badge from '@segment/matcha/components/Badge'
import { ChevronRightIcon } from '@segment/matcha/components/Icons/Common'

import CardBase, * as Card from '@segment/matcha/recipes/Card'
import SectionBase, * as Section from '@segment/matcha/recipes/Section'

const StyledSectionContentCenter = styled(Section.Contents)`
  display: flex;
  justify-content: center;
  align-content: center;
  padding-top: 0;
`

const StyledColumnTrack = styled(ColumnTrack)`
  ${({ theme }) => theme.breakpoints.only(`md`)} {
    max-width: 736px;
  }
  ${({ theme }) => theme.breakpoints.down(`md`)} {
    justify-items: stretch;
  }
`
const StyledSectionBase = styled(SectionBase)`
  max-width: 1280px;
  padding-top: 0;
  ${({ theme }) => theme.breakpoints.only(`md`)} {
    padding-top: 0;
    margin-top: 0;
  }
  ${Section.Wrapper} {
    padding-bottom: 80px;
  }
`

const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.palette.twilio.green[10]};
`

const cards = [
  {
    badge: `TypeScript`,
    title: `analytics-next`,
    description: `The hassle-free way to integrate analytics into any web application.`,
    link: `https://github.com/segmentio/analytics-next`,
  },
  {
    badge: `JavaScript`,
    title: `Evergreen`,
    description: `React UI framework that implements our Evergreen design system.`,
    link: `https://github.com/segmentio/evergreen`,
  },
  {
    badge: `Go`,
    title: `kafka-go`,
    description: `Easy-to-use APIs for interacting with Kafka.`,
    link: `https://github.com/segmentio/kafka-go`,
  },
]

export const Cards = () => {
  return (
    <Wrapper>
      <StyledSectionBase variant="noCopy">
        <StyledSectionContentCenter>
          <StyledColumnTrack center={true} columns={1} overrides={{ md: 2, lg: 3 }} gap={`medium`}>
            {cards.map((card) => (
              <CardBase key={card.title} href={card.link} layout="vertical">
                <Card.Body>
                  <Card.Accessories>
                    <Card.AccessoriesRow>
                      <Badge variant="outlined">{card.badge}</Badge>
                    </Card.AccessoriesRow>
                  </Card.Accessories>
                  <Card.Copy>
                    <Card.Headline tag="h2">{card.title}</Card.Headline>
                    <Card.Description tag="p">{card.description}</Card.Description>
                  </Card.Copy>
                  <Card.Action alignment="right">
                    <ChevronRightIcon />
                  </Card.Action>
                </Card.Body>
              </CardBase>
            ))}
          </StyledColumnTrack>
        </StyledSectionContentCenter>
      </StyledSectionBase>
    </Wrapper>
  )
}

export default Cards
